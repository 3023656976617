// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eqax2{width:100%;height:100%;border:none;border-radius:var(--wpp-border-radius-l)}.Hqncr{width:100%;height:calc(100% - 58px);border-radius:var(--wpp-border-radius-l)}.KjouY::part(icon-start){fill:#4d5358}.WOThL{display:block;width:100%}.WOThL::part(anchor){display:block;width:100%}.WOThL::part(inner){display:block}", "",{"version":3,"sources":["webpack://./src/components/assistant/apps/AppsList.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,WAAA,CACA,wCAAA,CAGF,OACE,UAAA,CACA,wBAAA,CACA,wCAAA,CAIA,yBACE,YAAA,CAIJ,OACE,aAAA,CACA,UAAA,CACA,qBACE,aAAA,CACA,UAAA,CAEF,oBACE,aAAA","sourcesContent":[".iframe {\n  width: 100%;\n  height: 100%;\n  border: none;\n  border-radius: var(--wpp-border-radius-l);\n}\n\n.iframeContainer {\n  width: 100%;\n  height: calc(100% - 58px);\n  border-radius: var(--wpp-border-radius-l);\n}\n\n.toolbarIcon {\n  &::part(icon-start) {\n    fill: #4D5358\n  }\n}\n\n.conversationItemTooltip {\n  display: block;\n  width: 100%;\n  &::part(anchor) {\n    display: block;\n    width: 100%;\n  }\n  &::part(inner) {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe": "eqax2",
	"iframeContainer": "Hqncr",
	"toolbarIcon": "KjouY",
	"conversationItemTooltip": "WOThL"
};
export default ___CSS_LOADER_EXPORT___;
