import {
  WppActionButton,
  WppIconDraft,
  WppIconMailAdd,
  WppIconNewspaper,
  WppIconApp,
  WppIconPeople,
  WppIconImage,
  WppSkeleton,
  WppTooltip,
  WppIconUserAdd,
  WppIconGrid,
} from '@platform-ui-kit/components-library-react'
// import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Fragment } from 'react'

import { showOverlay } from 'components/overlay/OverlayManager'
import { useAssistant } from 'hooks/useAssistant'
import { AppAPI } from 'types/app/app'

import styles from './AppsList.module.scss'

type IconKey =
  | 'CS Copywriter' // copywriter
  | 'CS Email Writer' // writeEmail
  | 'CS Headline Generator' // generateHeadlines
  | 'Foundational' // foundational
  | 'Build Personas' // buildPersonas
  | 'Focus Group' // focusGroup
  | 'Content Matrix' // contentMatrix

const knownIcons = [
  'CS Copywriter',
  'CS Email Writer',
  'CS Headline Generator',
  'Foundational',
  'Build Personas',
  'Focus Group',
  'Content Matrix',
]

const HardcodedIcons: { [key in IconKey]: React.ReactElement } = {
  'CS Copywriter': <WppIconDraft slot="icon-start" />,
  'CS Email Writer': <WppIconMailAdd slot="icon-start" />,
  'CS Headline Generator': <WppIconNewspaper slot="icon-start" />,
  Foundational: <WppIconImage slot="icon-start" />,
  'Build Personas': <WppIconUserAdd slot="icon-start" />,
  'Focus Group': <WppIconPeople slot="icon-start" />,
  'Content Matrix': <WppIconGrid slot="icon-start" />,
}

export default function AppsList({
  isExpanded = false,
  assistantWidth = 440,
  isAiAssistantOpen,
}: {
  isExpanded?: boolean
  assistantWidth?: number
  isAiAssistantOpen?: boolean
  assistantPosition?: { x: number; y: number }
}) {
  // const { osApi } = useOs()
  const { appsAPI, isLoadingApps } = useAssistant()

  const openApp = (app: AppAPI) => {
    //@ts-ignore
    // osApi.navigation.openCompactApp({
    //   name: app.name,
    //   url: app.appUrl,
    //   overlayProps: {
    //     ...(!app.resizable && { minWidth: app.width, maxWidth: app.width }),
    //     default: {
    //       x: window.innerWidth - (isAiAssistantOpen ? assistantWidth : 0) - 8 - (app.width || 0) - 8,
    //       y: 86,
    //       width: app.width,
    //       height: window.innerHeight - 63 - 48,
    //     },
    //     style: {
    //       zIndex: 1000,
    //     },
    //   },
    // })

    // TODO: local test
    const modal = showOverlay(
      'iframe',
      <div className={styles.iframeContainer}>
        <iframe className={styles.iframe} title={app.name} src={app.appUrl} />
      </div>,
      {
        defaultWidth: app.width,
        defaultPosition: {
          x: window.innerWidth - (!isAiAssistantOpen ? 44 : assistantWidth) - 8 - (app.width || 0) - 8,
          y: 24,
        },
        resizable: app.resizable,
        name: app.name,
        icon:
          !app.iconUrl && knownIcons.includes(app.name as any) ? (
            HardcodedIcons[app.name as IconKey]
          ) : (
            <WppIconApp slot="icon-start" />
          ),
      },
      () => {
        modal.close()
      },
    )
  }

  return (
    <>
      {isLoadingApps &&
        Array.from({ length: 5 }).map((_, index) => (
          <div key={index}>
            <WppSkeleton width={24} height={24} animation variant="rectangle" />
          </div>
        ))}
      {!isLoadingApps && (
        <Fragment>
          {(isAiAssistantOpen ? appsAPI : appsAPI.slice(0, 5)).map(app => {
            const Icon = HardcodedIcons[app.name as IconKey]
            return (
              <Fragment key={app.id}>
                <WppTooltip
                  config={{ placement: 'left' }}
                  className={clsx(styles.conversationItemTooltip, 'cancel-drag')}
                  text={app.name}
                >
                  <WppActionButton variant="secondary" onClick={() => openApp(app)}>
                    {!app.iconUrl && knownIcons.includes(app.name as any) ? Icon : <WppIconApp slot="icon-start" />}
                    {isExpanded ? app.name : ''}
                  </WppActionButton>
                </WppTooltip>
              </Fragment>
            )
          })}
        </Fragment>
      )}
    </>
  )
}
