// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IXHWV{display:flex;flex-direction:column;max-height:450px;gap:8px;transition:width .3s ease;position:relative;z-index:2}.l8SPw{width:32px;height:1px;color:var(--wpp-grey-color-300)}._U_OJ{width:250px}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatSidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,OAAA,CACA,yBAAA,CACA,iBAAA,CACA,SAAA,CAGF,OACE,UAAA,CACA,UAAA,CACA,+BAAA,CAGF,OACE,WAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  max-height: 450px;\n  gap: 8px;\n  transition: width 0.3s ease;\n  position: relative;\n  z-index: 2;\n}\n\n.horizontalDivider {\n  width: 32px;\n  height: 1px;\n  color: var(--wpp-grey-color-300);\n}\n\n.lengthContainer {\n  width: 250px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IXHWV",
	"horizontalDivider": "l8SPw",
	"lengthContainer": "_U_OJ"
};
export default ___CSS_LOADER_EXPORT___;
