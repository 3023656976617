// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LesbI{padding:8px 8px 4px;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-s);box-shadow:var(--wpp-box-shadow-m);position:absolute;left:45px;top:25px;z-index:2;min-width:100px;max-height:120px;overflow-y:auto;display:none}.LesbI .kYG8V{cursor:pointer;min-width:180px;margin-bottom:4px;padding:5px 8px;border-radius:var(--wpp-border-radius-s)}.LesbI .kYG8V:hover{background-color:var(--wpp-grey-color-200)}.LesbI .yppME{background-color:var(--wpp-grey-color-200)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/mentions/Mentions.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CACA,0CAAA,CACA,wCAAA,CACA,kCAAA,CACA,iBAAA,CACA,SAAA,CACA,QAAA,CACA,SAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,YAAA,CAEA,cACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,eAAA,CACA,wCAAA,CAEA,oBACE,0CAAA,CAIJ,cACE,0CAAA","sourcesContent":[".mentionsContainer {\n  padding: 8px 8px 4px;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-s);\n  box-shadow: var(--wpp-box-shadow-m);\n  position: absolute;\n  left: 45px;\n  top: 25px;\n  z-index: 2;\n  min-width: 100px;\n  max-height: 120px;\n  overflow-y: auto;\n  display: none;\n\n  .mention {\n    cursor: pointer;\n    min-width: 180px;\n    margin-bottom: 4px;\n    padding: 5px 8px;\n    border-radius: var(--wpp-border-radius-s);\n\n    &:hover {\n      background-color: var(--wpp-grey-color-200);\n    }\n  }\n\n  .active {\n    background-color: var(--wpp-grey-color-200);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mentionsContainer": "LesbI",
	"mention": "kYG8V",
	"active": "yppME"
};
export default ___CSS_LOADER_EXPORT___;
