import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { isAxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateConversation } from 'api/assistant/mutations/conversation/useCreateConversation'
import { useCreateQuestion } from 'api/assistant/mutations/conversation/useCreateQuestion'
import { useDeleteConversation } from 'api/assistant/mutations/conversation/useDeleteConversation'
import { usePatchConversation } from 'api/assistant/mutations/conversation/usePatchConversation'
import { useCreateFeedback } from 'api/assistant/mutations/feedback/useCreateFeedback'
import { usePatchFeedback } from 'api/assistant/mutations/feedback/usePatchFeedback'
import { usePatchUserSettings } from 'api/assistant/mutations/userSettings/usePatchUserSettings'
import { useFetchApps } from 'api/assistant/queries/apps/useFetchApps'
import { useFetchConversationMessages } from 'api/assistant/queries/conversation/useFetchConversationMessages'
import { useFetchSavedConversations } from 'api/assistant/queries/conversation/useFetchSavedConversations'
import { useSearchSavedConversations } from 'api/assistant/queries/conversation/useSearchSavedConversations'
import { useFetchTaskProgress } from 'api/assistant/queries/polling/useFetchTaskProgress'
import { useFetchUserSettings } from 'api/assistant/queries/userSettings/useFetchUserSettings'
import { AssistantSizes } from 'components/assistant/assistantPopover/assistantUserSettings/AssistantUserSettings'
import { FeedbackReaction, FeedbackSelectOptions } from 'components/assistant/chat/chatBubble/feedbackForm/FeedbackForm'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TaskDto, TaskDtoCreate } from 'types/dto/TaskDto'

import { useToast } from './useToast'
import { useFetchUserApps } from '../api/assistant/queries/userApps/useFetchUserApps'

interface CreateEmptyConversationArgs {
  question: string
}

interface AskQuestionArgs {
  conversationId: string
  question: string
}

interface PatchConversationArgs {
  conversationId: string
  name?: string
  pinned?: boolean
}

interface DeleteConversationArgs {
  conversationId: string
}

interface PatchUserSettingsDto {
  settingsId: string
  windowSize?: AssistantSizes
  mentionsEnabled?: boolean
}

interface AddFeedbackArgs {
  messageId: string
  reaction: FeedbackReaction
  subTypes?: FeedbackSelectOptions[] | null
  feedback?: string | null
}

interface PatchFeedbackArg {
  feedback_message_id: string
  reaction?: FeedbackReaction
  subTypes?: FeedbackSelectOptions[] | null
  feedback?: string | null
}

interface Props {
  searchQuery?: string
  enableHistory?: boolean
  enableSettings?: boolean
  selectedConversationId?: string
  activeTaskId?: string
}

export const useAssistant = (
  {
    searchQuery = '',
    enableHistory = false,
    enableSettings = false,
    selectedConversationId = '',
    activeTaskId = '',
  }: Props = {
    searchQuery: '',
    enableHistory: false,
    enableSettings: false,
    selectedConversationId: '',
    activeTaskId: '',
  },
) => {
  const { osContext } = useOs()
  const { t } = useTranslation()
  const tenantId = osContext.tenant.id
  const { id: userId } = osContext.userDetails
  const queryClient = useQueryClient()

  const { mutateAsync: createConversation } = useCreateConversation()
  const { mutateAsync: createQuestion } = useCreateQuestion()
  const { mutateAsync: patchConversationMutation } = usePatchConversation()
  const { mutateAsync: deleteConversationMutation } = useDeleteConversation()
  const { mutateAsync: patchUserSettingsMutation } = usePatchUserSettings()
  const { mutateAsync: createFeedback } = useCreateFeedback()
  const { mutateAsync: patchFeedbackMutation } = usePatchFeedback()

  const { showToast } = useToast()

  const startConversation = useCallback(
    async ({ question }: CreateEmptyConversationArgs): Promise<TaskDtoCreate | number | undefined> => {
      try {
        const response = await createConversation({
          tenantId,
          userId,
          messages: ['', question],
          tenantUrl: osContext.tenant.homeUrl.slice(0, -1),
        })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        console.error(err)
      }
    },
    [createConversation, osContext.tenant.homeUrl, tenantId, userId],
  )

  const askQuestion = useCallback(
    async ({ conversationId, question }: AskQuestionArgs): Promise<TaskDto | number | undefined> => {
      try {
        const response = await createQuestion({
          conversationId,
          question,
          tenantUrl: osContext.tenant.homeUrl.slice(0, -1),
        })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        console.error(err)
      }
    },
    [createQuestion, osContext.tenant.homeUrl],
  )

  const patchConversation = useCallback(
    async ({ conversationId, name, pinned }: PatchConversationArgs) => {
      try {
        await patchConversationMutation({ conversationId, name, pinned })
        queryClient.invalidateQueries([ApiQueryKeys.SAVED_CONVERSATIONS])
      } catch (err: any) {
        console.error(err)
        if (err.response.data.detail) {
          showToast({
            message: err.response.data.detail,
            type: 'error',
            duration: 4000,
          })
        }
      }
    },
    [patchConversationMutation, queryClient, showToast],
  )

  const deleteConversation = useCallback(
    async ({ conversationId }: DeleteConversationArgs) => {
      try {
        await deleteConversationMutation({ chatId: conversationId })
        queryClient.invalidateQueries([ApiQueryKeys.SAVED_CONVERSATIONS])
      } catch (err) {
        console.error(err)
      }
    },
    [deleteConversationMutation, queryClient],
  )

  const patchUserSettings = useCallback(
    async ({ settingsId, windowSize, mentionsEnabled }: PatchUserSettingsDto) => {
      try {
        await patchUserSettingsMutation({ settingsId, windowSize, mentionsEnabled })
        queryClient.invalidateQueries([ApiQueryKeys.USER_SETTINGS])
        showToast({
          message: t('settings.message_success'),
          type: 'success',
          duration: 4000,
        })
      } catch (err) {
        console.error(err)
        showToast({
          message: t('settings.message_failure'),
          type: 'error',
          duration: 4000,
        })
      }
    },
    [patchUserSettingsMutation, queryClient, showToast, t],
  )

  const addFeedback = useCallback(
    async ({ messageId, reaction, subTypes = null, feedback = null }: AddFeedbackArgs) => {
      const res = await createFeedback({ tenantId, userId, messageId, reaction, subTypes, feedback })
      return res.data
    },
    [createFeedback, tenantId, userId],
  )

  const patchFeedback = useCallback(
    async ({ feedback_message_id, reaction, subTypes = null, feedback = null }: PatchFeedbackArg) => {
      const res = await patchFeedbackMutation({ feedback_message_id, reaction, subTypes, feedback })
      return res.data
    },
    [patchFeedbackMutation],
  )

  const { data: savedConversations, isLoading: isLoadingSavedConversations } = useFetchSavedConversations({
    enabled: enableHistory,
    // TODO change chats_num when pagination is implemented
    params: { chats_num: 1000, userId: userId, tenantId: tenantId },
  })

  const { data: searchConversations, isLoading: isLoadingSearchConversations } = useSearchSavedConversations({
    enabled: searchQuery.length > 0,
    params: { userId: userId, tenantId: tenantId, searchQuery: searchQuery },
  })

  const { data: userSettingsAPI, isLoading: isLoadingUserSettings } = useFetchUserSettings({
    enabled: enableSettings,
    params: { userId: userId, tenantId: tenantId },
  })

  const { data: activeConversationMessages, isFetching: isFetchingConversationMessages } = useFetchConversationMessages(
    {
      enabled: selectedConversationId.length > 0,
      params: { itemsPerPage: 1000, chatId: selectedConversationId },
    },
  )

  const { data: activeTaskProgress, isFetching: isFetchingActiveTaskProgress } = useFetchTaskProgress({
    enabled: activeTaskId.length > 0,
    refetchInterval: 1000,
    refetchIntervalInBackground: true, // Stop polling when the browser tab is inactive
    params: { taskId: activeTaskId },
  })

  const { data: userAppsAPI, isLoading: isLoadingUserApps } = useFetchUserApps({
    enabled: enableSettings,
    params: { userId: userId, tenantId: tenantId },
  })

  const { data: appsAPI, isLoading: isLoadingApps } = useFetchApps({
    enabled: enableSettings,
  })

  return useMemo(
    () => ({
      startConversation,
      askQuestion,
      patchConversation,
      deleteConversation,
      isLoadingSavedConversations,
      savedConversations,
      searchConversations,
      isLoadingSearchConversations,
      userSettingsAPI,
      isLoadingUserSettings,
      patchUserSettings,
      addFeedback,
      activeConversationMessages,
      isFetchingConversationMessages,
      patchFeedback,
      activeTaskProgress,
      isFetchingActiveTaskProgress,
      userAppsAPI,
      isLoadingUserApps,
      appsAPI,
      isLoadingApps,
    }),
    [
      startConversation,
      askQuestion,
      patchConversation,
      deleteConversation,
      isLoadingSavedConversations,
      savedConversations,
      searchConversations,
      isLoadingSearchConversations,
      userSettingsAPI,
      isLoadingUserSettings,
      patchUserSettings,
      addFeedback,
      activeConversationMessages,
      isFetchingConversationMessages,
      patchFeedback,
      activeTaskProgress,
      isFetchingActiveTaskProgress,
      userAppsAPI,
      isLoadingUserApps,
      appsAPI,
      isLoadingApps,
    ],
  )
}
