import { MentionOption } from '../Mentions'

export const filterMentions = (mention: string, mentionOptions: MentionOption[]) => {
  const filteredMentionsArray: { id: string; display: string }[] = []
  let mentionWithoutAt: string = mention.replace('@', '')

  mentionOptions.forEach((option: { id: string; display: string }) => {
    const optionString = option.display.toLowerCase()
    mentionWithoutAt = mentionWithoutAt.trim()

    if (optionString.includes(mentionWithoutAt.toLowerCase())) {
      filteredMentionsArray.push(option)
    }
  })

  return filteredMentionsArray
}

export const mentionDropdownPosition = (inputElement: HTMLElement | null, dropdownElement: HTMLElement | null) => {
  if (inputElement && dropdownElement) {
    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const rects = range.getClientRects()
      if (rects.length > 0) {
        const rect = rects[0]
        const inputRect = inputElement.getBoundingClientRect()

        dropdownElement.style.top = `${rect.top - inputRect.top + 16}px`
        dropdownElement.style.left = `${rect.left - inputRect.left}px`
        dropdownElement.style.display = 'block'
      }
    }
  }
}
