// import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useState } from 'react'

import AppsList from 'components/assistant/apps/AppsList'
import styles from 'components/assistant/chat/chatSidebar/Sidebar.module.scss'

interface Props {
  isAiAssistantOpen?: boolean
  assistantPosition?: { x: number; y: number }
  assistantWidth?: number
}

export const Sidebar = (props: Props) => {
  // const { osApi } = useOs()
  const [expandedSidebar] = useState(false)
  // const { appsAPI } = useAssistant()

  // const handleIconClick = (app: AppAPI) => {
  //   const modal = showOverlay(
  //     'iframe',
  //     <div className={styles.iframeContainer}>
  //       <iframe className={styles.iframe} title={app.name} src={app.appUrl} />
  //     </div>,
  //     () => {
  //       modal.close()
  //     },
  //   )
  // }

  return (
    <div
      className={clsx([...(expandedSidebar ? [styles.lengthContainer] : []), styles.container])}
      // onMouseEnter={() => setExpandedSidebar(true)}
      // onMouseLeave={() => setExpandedSidebar(false)}
    >
      <AppsList {...props} />
      {/* <WppActionButton onClick={() => {}}>
        <WppIconMore direction="horizontal" size="m" color="#4D5358" />
      </WppActionButton> */}
    </div>
  )
}

export default Sidebar
